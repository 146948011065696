.custom__modal__backdrop {
    background-color: rgba(0, 0, 0, 0.5) !important;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10000;
}

.custom__modal {
    position: relative;
    width: 100vw;
    height: 100vh;
    overflow-y: auto;

}

.lg {
    width: 750px;
    max-width: 100%;
    min-height: 200px;
    /* height: 843px; */
}

.sm {
    max-width: 650px;
    min-height: 250px;
}

.modal__top {
    position: absolute;
    top: 50px;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}


.modal__center {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}


.modal__container {
    padding: 15px;
    border-radius: 5px;
    background: #ffffff;
    position: relative;
    margin-bottom: 100px;
}

.modal__close {
    position: absolute;
    /* top: 30px; */
    right: 15px;
    font-weight: 700;
    transition: 0.3s;
    cursor: pointer;
}

.modal__close:hover {
    color: grey;
}



/* .table>:not(caption)>*>* {
  
   background: none !important;
   } */


